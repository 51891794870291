<script setup lang="ts">
import icon_tab_main from '~/assets/image/home/icon_tab_main.png'
import icon_tab_main_selected from '~/assets/image/home/icon_tab_main_selected.png'
import icon_tab_beauty from '~/assets/image/home/icon_tab_beauty.png'
import icon_tab_beauty_selected from '~/assets/image/home/icon_tab_beauty_selected.png'
import icon_tab_promotion from '~/assets/image/home/icon_tab_promotion.png'
import icon_tab_promotion_selected from '~/assets/image/home/icon_tab_promotion_selected.png'

import icon_tab_gift from '~/assets/image/home/icon_tab_gift.png'
import icon_tab_gift_selected from '~/assets/image/home/icon_tab_gift_selected.png'
import icon_tab_mine from '~/assets/image/home/icon_tab_mine.png'
import icon_tab_mine_selected from '~/assets/image/home/icon_tab_mine_selected.png'

import icon_tab_subtract from '~/assets/image/home/icon_tab_subtract.png'
import icon_tab_subtract_selected from '~/assets/image/home/icon_tab_subtract_selected.png'

const active = ref(0)
function clickHandle() {
  window.sessionStorage.removeItem('tagsId')
}
</script>

<template>
  <router-view />

  <van-tabbar v-model="active" route :border="false" active-color="#FD5B03">
    <van-tabbar-item replace to="/" @click="clickHandle">
      <span>成人视频</span>
      <template #icon="props">
        <img :src="props.active ? icon_tab_main_selected : icon_tab_main" />
      </template>
    </van-tabbar-item>
    <!-- <van-tabbar-item replace to="/sis">
      <span>选片类型</span>
      <template #icon="props">
        <img :src="props.active ? icon_tab_beauty_selected : icon_tab_beauty" />
      </template>
    </van-tabbar-item> -->
    <van-tabbar-item replace to="/boon">
      <span>福利推荐</span>
      <template #icon="props">
        <img :src="props.active ? icon_tab_gift_selected : icon_tab_gift" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/AdIframe">
      <span>约炮招嫖</span>
      <template #icon="props">
        <img :src="props.active ? icon_tab_subtract_selected : icon_tab_subtract" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/activityIframe">
      <span>九游棋牌</span>
      <template #icon="props">
        <img :src="props.active ? icon_tab_promotion_selected : icon_tab_promotion" />
      </template>
    </van-tabbar-item>
    <van-tabbar-item replace to="/mine">
      <span>我的</span>
      <template #icon="props">
        <img :src="props.active ? icon_tab_mine_selected : icon_tab_mine" />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<style scoped>
:deep(.van-tabbar-item) {
  background: #090c1c;
  color: #b6b6b7;
}
</style>
